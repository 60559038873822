import React from 'react';

interface IconButtonProps {
  onClick?: () => void;
  title?: string;
  variant?: 'default' | 'danger';
  icon: React.ReactNode;
  className?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({ 
  onClick, 
  title,
  variant = 'default',
  icon,
  className = ''
}) => {
  const baseStyles = "p-2 rounded-lg transition-all duration-200 outline-none";
  const variantStyles = {
    default: "text-gray-500 hover:text-indigo-600 hover:bg-indigo-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
    danger: "text-gray-500 hover:text-red-600 hover:bg-red-50 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
  };

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      title={title}
    >
      {icon}
    </button>
  );
};