import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";

interface Option {
  id: string | number;
  name: string;
}

interface SelectFieldProps {
  name: string;
  label: string;
  control: Control<any>;
  options: Option[];
  placeholder?: string;
  errors?: FieldErrors;
  setValue: UseFormSetValue<any>;
}

export const SelectField = ({
  name,
  label,
  control,
  options,
  placeholder = "Selecione uma opção",
  errors,
  setValue,
}: SelectFieldProps) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-[#374451]"
      >
        {label}
      </label>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <select
              {...field}
              id={name}
              className="block w-full bg-white border border-gray-300 rounded-md shadow-sm text-[#374451] focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) => setValue(name, e.target.value)}
            >
              <option value="" className="text-[#374451]" disabled>
                {placeholder}
              </option>
              {options.map((option) => (
                <option className="text-[#374451]" key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          )}
        />
        {errors?.[name] && (
          <p className="mt-1 text-sm text-red-500">
            {errors[name]?.message as string}
          </p>
        )}
      </div>
    </div>
  );
};
