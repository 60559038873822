export const roles = [
  {
    id: "1",
    name: "Administrador",
  },
  {
    id: "2",
    name: "Funcionário",
  },
];
