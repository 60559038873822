import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { isValid, z } from 'zod';
import { InputField } from '../../components/Form/Input/InputField';
import { Modal } from '../../components/Modal';
import { categorySchema } from './schemas';
import { ICategoryForm } from '../../interfaces/category.interface';


interface ModalEditCategoryProps {
  setOpen: (value: boolean) => void;
  open: boolean;
  category: ICategoryForm | null;
}


type EditCategoryFormData = z.infer<typeof categorySchema>;

export function ModalEditCategory({ setOpen, open, category }: ModalEditCategoryProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<EditCategoryFormData>({
    resolver: zodResolver(categorySchema),
    defaultValues: {
      name: category?.name || '',
    }
  });

  const onSubmit = async (data: EditCategoryFormData) => {
    try {
      // Aqui você implementa a chamada à API para atualizar a categoria
      // await updateCategory(category?.id, data);

    } catch (error) {
      console.error('Erro ao atualizar categoria:', error);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  const renderActions = (
    <>
      <button
        type="submit"
        disabled={!isValid}
        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
      >
        Salvar
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
      >
        Cancelar
      </button>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={setOpen}
      title="Editar Categoria"
      actions={renderActions}
      maxWidth="2xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <InputField
            name="name"
            label="Nome da Categoria"
            placeholder="Digite o nome da categoria"
            control={control}
            errors={errors}
          />
        </div>
      </form>

    </Modal>
  );
}