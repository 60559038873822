import { Container } from "../../components/Container";
import {
  IServiceForm,
  servicesOptionsForm,
} from "../../interfaces/services.interface";
import { useState } from "react";
import { Paginator } from "../../components/Table/Paginator";
import { ListHeader } from "../../components/ListHeader";
import { Table } from "../../components/Table/Table";
import ModalEditService from "./ModalEditService";

export const ListServices = () => {
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const itemsPerPage = 3; // Itens por página
  const [selectedService, setSelectedService] = useState<IServiceForm | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Calcula os itens exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = servicesOptionsForm.slice(indexOfFirstItem, indexOfLastItem);


 const columns = [
    {
      header: 'Nome Serviço',
      accessor: 'name' as const,
      align: 'left' as const
    },
    {
      header: 'Tempo de Execução',
      accessor: 'averageExecutionTime' as const,
      align: 'right' as const
    },
    {
      header: 'Ações',
      accessor: 'actions' as const,
      align: 'right' as const
    }
  ];

  const handleEdit = (service: IServiceForm) => {
    setSelectedService(service);
    setIsEditModalOpen(true);
  };

  const actions = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      ),
      title: 'Editar Categoria',
      onClick: handleEdit
    }
  ];

  return (
    <Container>
      <>
        <ListHeader
          title="Lista de Serviços"
          subtitle="Visualize e gerencie todos os serviços disponíveis"
          buttonLabel="Novo Serviço"
          buttonLink="/services/add-service"
        />
        <div className="mt-8">
          <Table
            data={currentItems}
            columns={columns}
            actions={actions}
          />
        </div>

        <Paginator
          totalItems={servicesOptionsForm.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />

        <ModalEditService
          open={isEditModalOpen}
          setOpen={setIsEditModalOpen}
          service={selectedService}
        />
      </>
    </Container>
  );
};
