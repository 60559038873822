import { Routes, Route } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { AddCompany } from "../pages/Company/AddCompany";
import { ListEmployees } from "../pages/Employees/ListEmployees";
import { AddCategory } from "../pages/Categories/AddCategory";
import { PrivateRoute } from "./PrivateRoute";
import { SignIn } from "../pages/Auth/SignIn";
import { Register } from "../pages/Auth/Register";
import { AddEmployee } from "../pages/Employees/AddEmployee";
import { ListFlowWhatsApp } from "../pages/FlowWhatsApp/ListFlowWhatsApp";
import { AddFlowWhatsApp } from "../pages/FlowWhatsApp/AddFlowWhatsApp";
import { ListCategories } from "../pages/Categories/ListCategories";
import { ListCompanies } from "../pages/Company/ListCompanies";
import { AddService } from "../pages/Service/AddService";
import { ListServices } from "../pages/Service/ListServices";

export const RoutesApp = () => {
  return (
    <Routes>
      {/* Rota Pública */}
      <Route path="/" element={<SignIn />} />
      <Route path="/register" element={<Register />} />

      {/* Rotas Privadas */}
      <Route element={<PrivateRoute />}>
        <Route path="/employees" element={<Layout><ListEmployees /></Layout>} />
        <Route path="/employees/add-employee" element={<Layout><AddEmployee /></Layout>} />
        <Route path="/flow" element={<Layout><ListFlowWhatsApp /></Layout>} />
        <Route path="/flow/new" element={<Layout><AddFlowWhatsApp /></Layout>} />
        <Route path="/companies" element={<Layout><ListCompanies /></Layout>} />
        <Route path="/companies/add-company" element={<Layout><AddCompany /></Layout>} />
        <Route path="/services" element={<Layout><ListServices /></Layout>} />
        <Route path="/services/add-service" element={<Layout><AddService /></Layout>} />
        <Route path="/categories" element={<Layout><ListCategories /></Layout>} />
        <Route path="/categories/add-category" element={<Layout><AddCategory /></Layout>} />
      </Route>
    </Routes>
  );
};

const Layout = ({ children }: { children: React.ReactNode }) => (
  <div>
    <Sidebar />
    <div className="xl:pl-72">
      {children}
    </div>
  </div>
);
