import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Modal } from "../../components/Modal";
import { InputField } from "../../components/Form/Input/InputField";
import { SelectField } from "../../components/Form/SelectField";
import { MultiSelectCheckbox } from "../../components/Form/MultiSelectCheckbox";
import { servicesOptionsForm } from "../../interfaces/services.interface";
import { roles } from "../../interfaces/role.interface";
import { IEmployeeForm } from "../../interfaces/employees.interface";

const employeeSchema = z.object({
  name: z.string().min(1, "Nome é obrigatório"),
  email: z.string().email("Email inválido"),
  phone: z.object({
    ddd: z.number().min(2, "DDD deve ter 2 dígitos"),
    phone: z.number().min(9, "Telefone deve ter 8 ou 9 dígitos"),
  }),
  department: z.string().min(1, "Departamento é obrigatório"),
  roles: z.string(),
  services: z.array(z.string()).default([]),
});

type FormData = z.infer<typeof employeeSchema>;

interface IEditEmployee {
  setOpen: (value: boolean) => void;
  open: boolean;
  employee: IEmployeeForm | undefined;
}

export function ModalEditEmployee({ setOpen, open, employee }: IEditEmployee) {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(employeeSchema),
    defaultValues: employee,
  });

  const selectedServices = watch("services") || [];

  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data: FormData) => {
    console.log("Dados atualizados do Funcionário:", data);
    // Adicione aqui a lógica de atualização
    handleCancel();
  };

  const servicesOptions = servicesOptionsForm.map((service) => ({
    value: service.id || "",
    label: service.name,
  }));

  const renderActions = (
    <>
      <button
        type="submit"
        disabled={!isValid}
        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
      >
        Salvar
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
      >
        Cancelar
      </button>
    </>
  );

  return (
    <Modal 
      open={open} 
      onClose={setOpen}
      title="Editar Funcionário"
      actions={renderActions}
      maxWidth="2xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <InputField
              name="name"
              label="Nome do Funcionário"
              placeholder="Digite o nome do funcionário"
              control={control}
              errors={errors}
            />

            <InputField
              name="email"
              label="Email do Funcionário"
              placeholder="Digite o Email do funcionário"
              control={control}
              type="email"
              errors={errors}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <InputField
              name="department"
              label="Departamento do Funcionário"
              placeholder="Digite o Departamento do funcionário"
              control={control}
              errors={errors}
            />

            <SelectField
              name="roles"
              label="Permissão do usuário"
              placeholder="Selecione uma permissão"
              control={control}
              options={roles}
              errors={errors}
              setValue={setValue}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-1">
                  <InputField
                    name="phone.ddd"
                    label="DDD"
                    placeholder="00"
                    control={control}
                    type="number"
                    maxLength={2}
                    errors={errors}
                  />
                </div>
                <div className="col-span-3">
                  <InputField
                    name="phone.phone"
                    label="Telefone"
                    placeholder="000000000"
                    control={control}
                    type="number"
                    errors={errors}
                  />
                </div>
              </div>
            </div>

            <MultiSelectCheckbox
              label="Categorias dos Serviços"
              options={servicesOptions}
              selectedOptions={selectedServices}
              onToggleOption={(option) => {
                const newSelectedServices = selectedServices.includes(option.value)
                  ? selectedServices.filter((id) => id !== option.value)
                  : [...selectedServices, option.value];

                setValue("services", newSelectedServices);
                return true;
              }}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}
