interface FormTitleProps {
  title: string;
  subtitle?: string;
}

export const FormHeader = ({ title, subtitle }: FormTitleProps) => {
  return (
    <>
      <div className="border-b border-gray-200 pb-5">
        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{title}</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{subtitle}</p>
      </div>
    </>
  );
};
