import { useState } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface Option {
  value: string;
  label: string;
  description?: string;
}

interface MultiSelectCheckboxProps {
  label: string;
  options: Option[];
  selectedOptions: string[];
  onToggleOption: (option: Option) => boolean;
}

export function MultiSelectCheckbox({
  label,
  options,
  selectedOptions,
  onToggleOption,
}: MultiSelectCheckboxProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedItems = options.filter((option) => 
    selectedOptions.includes(option.value)
  );

  return (
    <div className="sm:col-span-4">
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        {selectedItems.length > 0 && (
          <div className="mb-2 flex flex-wrap gap-2">
            {selectedItems.map((item) => (
              <span
                key={item.value}
                className="inline-flex items-center gap-x-0.5 rounded-md bg-indigo-50 px-2 py-1 text-sm font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
              >
                {item.label}
                <button
                  type="button"
                  onClick={() => onToggleOption(item)}
                  className="group relative -mr-1 h-4 w-4 hover:text-indigo-900"
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </span>
            ))}
          </div>
        )}

        <fieldset className="border border-gray-300 rounded-md">
          <legend className="sr-only">{label}</legend>
          
          <div className="px-3 py-2 border-b border-gray-200">
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Buscar opções..."
              />
            </div>
          </div>

          <div className="divide-y divide-gray-200 max-h-48 overflow-y-auto">
            {filteredOptions.length === 0 ? (
              <div className="px-3 py-2 text-sm text-gray-500 text-center">
                Nenhuma opção encontrada
              </div>
            ) : (
              filteredOptions.map((option) => (
                <div key={option.value} className="relative flex gap-3 px-3 py-2.5">
                  <div className="min-w-0 flex-1 text-sm/6">
                    <label
                      htmlFor={`option-${option.value}`}
                      className="font-medium text-gray-900"
                    >
                      {option.label}
                    </label>
                    {option.description && (
                      <p
                        id={`${option.value}-description`}
                        className="text-gray-500"
                      >
                        {option.description}
                      </p>
                    )}
                  </div>
                  <div className="flex h-6 shrink-0 items-center">
                    <div className="group grid size-4 grid-cols-1">
                      <input
                        id={`option-${option.value}`}
                        type="checkbox"
                        checked={selectedOptions.includes(option.value)}
                        onChange={() => onToggleOption(option)}
                        aria-describedby={option.description ? `${option.value}-description` : undefined}
                        className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
                      />
                      <svg
                        fill="none"
                        viewBox="0 0 14 14"
                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      >
                        <path
                          d="M3 8L6 11L11 3.5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:checked]:opacity-100"
                        />
                        <path
                          d="M3 7H11"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:indeterminate]:opacity-100"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </fieldset>
      </div>
    </div>
  );
}