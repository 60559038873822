import React from 'react';
import { Link } from 'react-router-dom';
import { IFlowWhatsApp } from '../../../interfaces/flowWhatsApp.interface';

interface FlowCardProps {
  flow: IFlowWhatsApp;
  onDelete: (companyId: string) => void;
}

export const FlowCard: React.FC<FlowCardProps> = ({ flow, onDelete }) => {
  return (
    <div
      className="group relative bg-white rounded-xl p-6 transition-all duration-300 ease-in-out
                 border border-gray-200 hover:border-indigo-100
                 hover:bg-gradient-to-b hover:from-white hover:to-indigo-50/30"
    >
      {/* Header Section */}
      <div className="flex items-start justify-between">
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <h3 className="text-sm font-semibold text-gray-900 group-hover:text-indigo-600 
                         transition-colors duration-200">
              {flow.name}
            </h3>
            <span className="px-2.5 py-0.5 text-xs font-medium bg-indigo-50 text-indigo-700 
                         rounded-full inline-flex items-center">
              Flow
            </span>
          </div>
          <div className="flex items-center gap-2 text-gray-500 text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
            </svg>
            <span className="font-medium">{Object.keys(flow.steps).length} passos</span>
          </div>
        </div>

        {/* Action Buttons */}
        {/* <div className="flex items-center gap-1.5">
          <Link
            to={`/flow/edit/${flow.companyId}`}
            className="p-2 text-gray-500 hover:text-indigo-600 hover:bg-indigo-50 
                     rounded-lg transition-all duration-200 outline-none
                     focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            title="Editar Flow"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
          </Link>
          <button
            onClick={() => onDelete(flow.companyId)}
            className="p-2 text-gray-500 hover:text-red-600 hover:bg-red-50 
                     rounded-lg transition-all duration-200 outline-none
                     focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            title="Excluir Flow"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </button>
        </div> */}
      </div>

      {/* Steps Preview Section */}
      <div className="mt-6 space-y-3">
        {Object.entries(flow.steps).slice(0, 3).map(([key, step], index) => (
          <div 
            key={key} 
            className="group/step relative bg-gray-50 p-4 rounded-lg border border-gray-200
                     hover:bg-white hover:border-indigo-200 hover:shadow-sm
                     transition-all duration-200"
          >
            <div className="absolute left-0 top-1/2 -translate-y-1/2 w-1 h-12 
                         bg-gradient-to-b from-indigo-500 to-indigo-600 
                         rounded-r opacity-0 group-hover/step:opacity-100 
                         transition-opacity duration-200"
            />
            <div className="pl-3">
              <div className="flex items-center gap-2 mb-1">
                <span className="text-xs font-medium text-gray-400">
                  Passo {index + 1}
                </span>
                <div className="h-4 w-px bg-gray-300" />
                <span className="text-sm font-semibold text-indigo-600">
                  {step.name}
                </span>
              </div>
              <div className="text-sm text-gray-600 group-hover/step:text-gray-700 
                           transition-colors duration-200">
                {step.description}
              </div>
            </div>
          </div>
        ))}
        
        {Object.keys(flow.steps).length > 3 && (
          <div className="flex items-center justify-center py-3 px-4 bg-gray-50 
                       rounded-lg border border-dashed border-gray-300
                       group-hover:border-indigo-200 transition-colors duration-200">
            <span className="text-sm font-medium text-gray-500 group-hover:text-indigo-600
                         transition-colors duration-200">
              + {Object.keys(flow.steps).length - 3} passos adicionais
            </span>
          </div>
        )}
      </div>
    </div>
  );
}; 