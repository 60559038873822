import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { putService } from "../../services/services.service";
import { useEffect } from "react";
import { ICompany } from "../../interfaces/company.interface";
import { putCompany } from "../../services/company.service";

interface IEditService {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCompany: React.Dispatch<React.SetStateAction<ICompany | undefined>>;
  open: boolean;
  company: ICompany | undefined;
}

export default function ModalEditCompany({
  setOpen,
  open,
  company,
  setCompany,
}: IEditService) {
  const { control, handleSubmit, reset } = useForm<ICompany>({
    defaultValues: {
      name: company?.name,
      cnpj: company?.cnpj
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = (data: ICompany) => {
    putCompany(data)
      .then(() => {
        window.alert("Sucesso ao editar empresa!");

        queryClient.invalidateQueries({
          queryKey: ["services"],
        });
      })
      .catch(() => {
        window.alert("Erro ao editar empresa!");
      })
      .finally(() => {
        handleCancel();
      });
  };

  useEffect(() => {
    if (company) {
      reset(company);
    }
  }, [company, reset]);

  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Editar Empresa
                    </h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Nome Empresa
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <Controller
                              name="name"
                              control={control}
                              defaultValue={company?.name}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  name="name"
                                  className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="XXXXXXXX"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="cnpj"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          CNPJ
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <Controller
                              name="cnpj"
                              control={control}
                              defaultValue={company?.cnpj}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  name="cnpj"
                                  className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                  placeholder="XX"
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                Editar
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => handleCancel()}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancelar
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
