import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Container } from "../../components/Container";
import { InputField } from "../../components/Form/Input/InputField";
import { FormHeader } from "../../components/Form/FormHeader";
import { FormFooter } from "../../components/Form/FormFooter";
import { useCategory } from "../../services/hooks/useCategory";
import { ICategoryForm as ICategory } from "../../interfaces/category.interface";
import { categorySchema } from "./schemas";

type FormData = z.infer<typeof categorySchema>;

export const AddCategory = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(categorySchema),
  });

  const { addCategory } = useCategory();

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const formData: ICategory = {
      name: data.name,
      company_id: "1",
    };

    await addCategory.mutateAsync(formData);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <FormHeader
              title="Nova Categoria"
              subtitle="Organize seus serviços criando categorias personalizadas"
            />

            <div className="mt-8 space-y-6 max-w-xl">
              <InputField
                name="name"
                label="Nome Categoria"
                placeholder="Digite o nome da categoria"
                control={control}
                errors={errors}
              />
            </div>
          </div>

          <FormFooter
            cancelLink="/categories"
            onCancel={handleCancel}
            isValid={isValid}
          />
        </div>
      </form>
    </Container>
  );
};
