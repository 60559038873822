export interface ICategory {
  id: string;
  name: string;
  company_id: string;
  created_at: string;
  active: boolean;
}

export interface ICategoryForm {
  name: string;
  company_id: string;
}

export const categoriesData: ICategory[] = [
    {
      id: "1",
      name: "Limpeza",
      company_id: "123",
      created_at: "2023-12-01T10:00:00Z",
      active: true,
    },
    {
      id: "2",
      name: "Manutenção",
      company_id: "123",
      created_at: "2023-12-02T11:00:00Z",
      active: true,
    },
    {
      id: "3",
      name: "Beleza",
      company_id: "123",
      created_at: "2023-12-03T12:00:00Z",
      active: false,
    },
    {
      id: "4",
      name: "Tecnologia",
      company_id: "124",
      created_at: "2023-12-04T13:00:00Z",
      active: true,
    },
    {
      id: "5",
      name: "Serviços Gerais",
      company_id: "124",
      created_at: "2023-12-05T14:00:00Z",
      active: true,
    },
  ];
  