import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Container } from "../../components/Container";
import { FormHeader } from "../../components/Form/FormHeader";
import { FormFooter } from "../../components/Form/FormFooter";
import { MultiSelectCheckbox } from "../../components/Form/MultiSelectCheckbox";
import { servicesOptionsForm } from "../../interfaces/services.interface";
import { InputField } from "../../components/Form/Input/InputField";
import { SelectField } from "../../components/Form/SelectField";
import { roles } from "../../interfaces/role.interface";

const employeeSchema = z.object({
  name: z.string().min(1, "Nome é obrigatório"),
  email: z.string().email("Email inválido"),
  phone: z.object({
    ddd: z.number().min(2, "DDD deve ter 2 dígitos"),
    phone: z.number().min(9, "Telefone deve ter 8 ou 9 dígitos"),
  }),
  department: z.string().min(1, "Departamento é obrigatório"),
  // companyId: z.string().min(1, "ID da empresa é obrigatório"),
  // active: z.boolean().default(true),
  roles: z.string(),
  services: z.array(z.string()).default([]),
});

type FormData = z.infer<typeof employeeSchema>;

export const AddEmployee = () => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(employeeSchema),
  });

  const selectedServices = watch("services") || [];

  const handleCancel = () => {
    reset();
  };

  const onSubmit = (data: FormData) => {
    console.log("Dados do Funcionário:", data);
  };

  const servicesOptions = servicesOptionsForm.map((service) => ({
    value: service.id || "",
    label: service.name,
  }));

  console.log(watch());
  console.log(errors);


  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <FormHeader
              title="Novo Funcionário"
              subtitle="Adicione um novo funcionário"
            />

            <div className="mt-8 space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <InputField
                  name="name"
                  label="Nome do Funcionário"
                  placeholder="Digite o nome do funcionário"
                  control={control}
                  errors={errors}
                />

                <InputField
                  name="email"
                  label="Email do Funcionário"
                  placeholder="Digite o Email do funcionário"
                  control={control}
                  type="email"
                  errors={errors}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <InputField
                  name="department"
                  label="Departamento do Funcionário"
                  placeholder="Digite o Departamento do funcionário"
                  control={control}
                  errors={errors}
                />

                <SelectField
                  name="roles"
                  label="Permissão do usuário"
                  placeholder="Selecione uma permissão"
                  control={control}
                  options={roles}
                  errors={errors}
                  setValue={setValue}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-1">
                    <InputField
                      name="phone.ddd"
                      label="DDD"
                      placeholder="00"
                      control={control}
                      type="number"
                      maxLength={2}
                      errors={errors}
                    />
                  </div>
                  <div className="col-span-3">
                    <InputField
                      name="phone.phone"
                      label="Telefone"
                      placeholder="000000000"
                      control={control}
                      type="number"
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="col-span-1"></div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <MultiSelectCheckbox
                  label="Categorias dos Serviços"
                  options={servicesOptions}
                  selectedOptions={selectedServices}
                  onToggleOption={(option) => {
                    const newSelectedServices = selectedServices.includes(
                      option.value
                    )
                      ? selectedServices.filter((id) => id !== option.value)
                      : [...selectedServices, option.value];

                    setValue("services", newSelectedServices);
                    return true;
                  }}
                />
              </div>
            </div>
          </div>

          <FormFooter
            cancelLink="/employees"
            onCancel={handleCancel}
            isValid={isValid}
          />
        </div>
      </form>
    </Container>
  );
};
