import React from 'react';
import { IconButton } from '../Button/IconButton';

interface TableAction<T> {
  icon: React.ReactNode;
  title: string;
  onClick: (item: T) => void;
  variant?: 'default' | 'danger';
  show?: (item: T) => boolean;
}

interface Column<T> {
  header: string;
  accessor: keyof T | 'actions';
  align?: 'left' | 'right' | 'center';
  render?: (item: T) => React.ReactNode;
}

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  actions?: TableAction<T>[];
  idField?: keyof T;
}

export function Table<T>({ 
  data, 
  columns, 
  actions = [],
  idField = 'id' as keyof T 
}: TableProps<T>) {
  return (
    <div className="flow-root">
      <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
        <div className="inline-block min-w-full">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column) => (
                  <th
                    key={String(column.accessor)}
                    scope="col"
                    className={`px-4 py-3.5 text-${column.align || 'left'} text-xs font-medium uppercase tracking-wider text-gray-500`}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data.map((item, index) => (
                <tr 
                  key={item[idField] as string || index}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  {columns.map((column) => (
                    <td
                      key={String(column.accessor)}
                      className={`whitespace-nowrap px-4 py-4 text-sm text-${column.align || 'left'}`}
                    >
                      {column.accessor === 'actions' ? (
                        <div className="flex justify-end gap-2">
                          {actions
                            .filter(action => !action.show || action.show(item))
                            .map((action, actionIndex) => (
                              <IconButton
                                key={actionIndex}
                                icon={action.icon}
                                title={action.title}
                                variant={action.variant}
                                onClick={() => action.onClick(item)}
                                className="transition-transform hover:scale-105"
                              />
                            ))}
                        </div>
                      ) : column.render ? (
                        column.render(item)
                      ) : (
                        <div className="font-medium text-gray-900">
                          {String(item[column.accessor as keyof T])}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}