import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ReactNode } from "react";

interface ModalProps {
  open: boolean;
  onClose: (value: boolean) => void;
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
  maxWidth?: "sm" | "md" | "lg" | "xl" | "2xl";
}

export function Modal({ open, onClose, title, children, actions, maxWidth = "xl" }: ModalProps) {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={`relative flex flex-col transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:p-0 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 ${
              maxWidth === "sm" ? "sm:max-w-sm" :
              maxWidth === "md" ? "sm:max-w-md" :
              maxWidth === "lg" ? "sm:max-w-lg" :
              maxWidth === "xl" ? "sm:max-w-xl" :
              "sm:max-w-2xl"
            }`}
          >
            {title && (
              <div className="sticky top-0 bg-white px-4 pb-4 pt-5 sm:px-6 sm:pt-6 border-b border-gray-200">
                <h2 className="text-lg font-semibold leading-6 text-gray-900">
                  {title}
                </h2>
              </div>
            )}

            <div className="flex-1 overflow-y-auto px-4 py-5 sm:p-6 max-h-[calc(100vh-16rem)]">
              {children}
            </div>

            {actions && (
              <div className="sticky bottom-0 bg-white px-4 py-4 sm:px-6 border-t">
                <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  {actions}
                </div>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
} 