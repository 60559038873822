import { Control, Controller, FieldErrors } from "react-hook-form";
import InputMask from "react-input-mask";

interface InputFieldProps {
  name: string;
  control: Control<any>;
  label: string;
  placeholder: string;
  errors: FieldErrors<any>;
  type?: string;
  mask?: string;
  maxLength?: number;
}

export const InputField = ({ name, control, label, placeholder, errors, mask, type = "text", maxLength }: InputFieldProps) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          mask ? (
            <InputMask
              {...field}
              mask={mask}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={placeholder}
            />
          ) : (
            <input
              {...field}
              type={type}
              maxLength={maxLength}
              className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={placeholder}
            />
          )
        )}
      />
      {errors[name] && (
        <span className="text-sm text-red-600">{errors[name]?.message as string}</span>
      )}
    </div>
  );
};
