import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ICompany } from "../../interfaces/company.interface";
import api from "../api";
import toast from "react-hot-toast";

export const COMPANY_QUERY_KEY = "companies";
const base_url = "/smartagenda/Company"


export function useCompany() {
  const queryClient = useQueryClient();

  const addCompany = useMutation({
    mutationFn: (data: ICompany) => api.post(`${base_url}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMPANY_QUERY_KEY] });
      toast.success("Sucesso ao criar empresa!");
    },
    onError: () => {
      toast.error("Erro ao criar empresa!");
    },
  });

  return {
    addCompany
  };
}
