import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Container } from "../../components/Container";
import { categoriesData } from "../../interfaces/category.interface";
import { FormHeader } from "../../components/Form/FormHeader";
import { InputField } from "../../components/Form/Input/InputField";
import { FormFooter } from "../../components/Form/FormFooter";
import { SelectField } from "../../components/Form/SelectField";
import { useService } from "../../services/hooks/useService";
import { IServiceForm } from "../../interfaces/services.interface";
import { serviceSchema } from "./schema";


type FormData = z.infer<typeof serviceSchema>;

export const AddService = () => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(serviceSchema),
  });

  const { addService } = useService();

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const formData: IServiceForm = {
      name: data.name,
      price: Number(data.price),
      averageExecutionTime: Number(data.averageExecutionTime),
      categoryName: data.categoryName,
      companyId: "1",
    };

    await addService.mutateAsync(formData);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <FormHeader
              title="Novo Serviço"
              subtitle="Adicione um novo serviço à sua lista de serviços"
            />

            <div className="mt-8 space-y-6 max-w-xl">
              <SelectField
                name="categoryName"
                label="Categoria Serviço"
                placeholder="Selecione uma categoria"
                control={control}
                options={categoriesData}
                errors={errors}
                setValue={setValue}
              />

              <InputField
                name="name"
                label="Nome do Serviço"
                placeholder="Digite o nome do serviço"
                control={control}
                errors={errors}
              />

              <InputField
                name="price"
                label="Preço"
                placeholder="Digite o preço do serviço"
                type="number"
                control={control}
                errors={errors}
              />

              <InputField
                name="averageExecutionTime"
                label="Tempo Médio de Execução"
                placeholder="Digite o tempo em minutos"
                type="number"
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <FormFooter
            cancelLink="/services"
            onCancel={handleCancel}
            isValid={isValid}
          />
        </div>
      </form>
    </Container>
  );
};
