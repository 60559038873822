import { useForm } from "react-hook-form";
import { IServiceForm } from "../../interfaces/services.interface";
import { serviceSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Modal } from "../../components/Modal";
import { InputField } from "../../components/Form/Input/InputField";
import { SelectField } from "../../components/Form/SelectField";
import { categoriesData } from "../../interfaces/category.interface";

interface IEditService {
  setOpen: (value: boolean) => void;
  open: boolean;
  service: IServiceForm | null;
}

type FormData = z.infer<typeof serviceSchema>;

export default function ModalEditService({
  setOpen,
  open,
  service,
}: IEditService) {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid, },
  } = useForm<FormData>({
    resolver: zodResolver(serviceSchema),
  });


  const onSubmit = async (data: FormData) => {
    try {
      // Aqui você implementa a chamada à API para atualizar a categoria
      // await updateCategory(category?.id, data);

    } catch (error) {
      console.error('Erro ao atualizar categoria:', error);
    }
  };



  const handleCancel = () => {
    setOpen(false);
    reset();
  };

  const renderActions = (
    <>
      <button
        type="submit"
        disabled={!isValid}
        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
      >
        Salvar
      </button>
      <button
        type="button"
        onClick={handleCancel}
        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
      >
        Cancelar
      </button>
    </>
  );


  return (
    <Modal
      open={open}
      onClose={setOpen}
      title="Alterar informações do Serviço"
      actions={renderActions}
      maxWidth="2xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">

            <div className="mt-8 space-y-6 max-w-xl">
              <SelectField
                name="categoryName"
                label="Categoria Serviço"
                placeholder="Selecione uma categoria"
                control={control}
                options={categoriesData}
                errors={errors}
                setValue={setValue}
              />

              <InputField
                name="name"
                label="Nome do Serviço"
                placeholder="Digite o nome do serviço"
                control={control}
                errors={errors}
              />

              <InputField
                name="price"
                label="Preço"
                placeholder="Digite o preço do serviço"
                type="number"
                control={control}
                errors={errors}
              />

              <InputField
                name="averageExecutionTime"
                label="Tempo Médio de Execução"
                placeholder="Digite o tempo em minutos"
                type="number"
                control={control}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
