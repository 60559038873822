import { useState } from "react";
import { Container } from "../../components/Container";
import {
  categoriesData,
  ICategoryForm,
} from "../../interfaces/category.interface";
import { Paginator } from "../../components/Table/Paginator";
import { Table } from "../../components/Table/Table";
import { ModalEditCategory } from "./ModalEditCategory";
import { ListHeader } from "../../components/ListHeader";

export const ListCategories = () => {
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const itemsPerPage = 3; // Itens por página
  const [selectedCategory, setSelectedCategory] = useState<ICategoryForm | null>(null);
  const [openModalSeeDetails, setOpenModalSeeDetails] = useState(false);
  // Calcula os itens exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categoriesData.slice(indexOfFirstItem, indexOfLastItem);

  const handleOpenModal = (category: ICategoryForm) => {
    setSelectedCategory(category);
    setOpenModalSeeDetails(true);
  };

  const columns = [
    {
      header: 'Nome Categoria',
      accessor: 'name' as const,
      align: 'left' as const
    },
    {
      header: 'Ações',
      accessor: 'actions' as const,
      align: 'right' as const
    }
  ];

  const actions = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      ),
      title: 'Editar Categoria',
      onClick: handleOpenModal
    },
  ];

  return (
    <Container>
      <>
        <ListHeader
          title="Lista de Categorias"
          subtitle="Organize seus serviços em categorias"
          buttonLabel="Nova Categoria"
          buttonLink="/categories/add-category"
        />
        <div className="mt-8">
          <Table
            data={currentItems}
            columns={columns}
            actions={actions}
          />
        </div>

        {/* Paginator */}
        <Paginator
          totalItems={categoriesData.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />


        <ModalEditCategory
          open={openModalSeeDetails}
          setOpen={setOpenModalSeeDetails}
          category={selectedCategory}
        />

      </>
    </Container>
  );
};
