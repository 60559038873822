import { Container } from "../../components/Container";
import { useState } from "react";
import { employeesMockData, IEmployeeForm } from "../../interfaces/employees.interface";
import { ModalEditEmployee } from "./ModalEditEmployee";
import { Table } from "../../components/Table/Table";
import { Paginator } from "../../components/Table/Paginator";
import { ListHeader } from "../../components/ListHeader";

export const ListEmployees = () => {
  const [openModalSeeDetails, setOpenModalSeeDetails] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployeeForm>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  const handleOpenModal = (employee: IEmployeeForm) => {
    setSelectedEmployee(employee);
    setOpenModalSeeDetails(true);
  };

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employeesMockData.slice(indexOfFirstItem, indexOfLastItem);


  const columns = [
    {
      header: "Nome",
      accessor: "name" as const,
      align: "left" as const,
    },
    {
      header: "Email",
      accessor: "email" as const,
      align: "left" as const,
    },
    {
      header: 'Ações',
      accessor: 'actions' as const,
      align: 'right' as const
    }
  ];


  const actions = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      ),
      title: 'Editar Funcionário',
      onClick: handleOpenModal
    },
  ];


  return (
    <>
      <Container>
        <>
          <ListHeader
            title="Lista de Funcionários"
            subtitle="Gerencie todos os funcionários da sua empresa"
            buttonLabel="Novo Funcionário"
            buttonLink="/employees/add-employee"
          />

          <Table
            data={currentItems}
            columns={columns}
            actions={actions}

          />

          <Paginator
            totalItems={employeesMockData.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </>
      </Container>

      <ModalEditEmployee
        open={openModalSeeDetails}
        setOpen={setOpenModalSeeDetails}
        employee={selectedEmployee}
      />
    </>
  );
};
