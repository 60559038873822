interface IContainer {
  children: JSX.Element;
}

export const Container = ({ children }: IContainer) => {
  return (
    <div className="mx-auto max-w-7xl mt-8 px-4 sm:px-6 lg:px-8 py-8">
      <>
      {children}
      </>
    </div>
  );
};
