import { INewService, IServiceForm } from "./services.interface";

export interface IEmployeeForm {
  name: string;
  email: string;
  phone: {
    ddd: number;
    phone: number;
  },
  department: string;
  companyId: string;
  active: boolean;
  role: string;
  services: Array<string>;
}

export interface IEmployee {
  id: string;
  name: string;
  email: string;
  phone: number;
  company_id: string;
  role: string;
  created_at: string;
  active: boolean;
  services: Array<INewService>;
}


export const employeesMockData: IEmployeeForm[] = [
  {
    name: "João Silva",
    email: "joao.silva@example.com",
    phone: {
      ddd: 11,
      phone: 999999999,
    },
    department: "Limpeza",
    companyId: "123",
    active: true,
    role: "EMPLOYEE",
    services: ["1", "2"],
  },
  {
    name: "Maria Oliveira",
    email: "maria.oliveira@example.com",
    phone: {
      ddd: 21,
      phone: 988888888,
    },
    department: "Manutenção",
    companyId: "123",
    active: true,
    role: "MANAGER",
    services: ["3", "4"],
  },
  {
    name: "Carlos Santos",
    email: "carlos.santos@example.com",
    phone: {
      ddd: 31,
      phone: 977777777,
    },
    department: "Jardinagem",
    companyId: "123",
    active: false,
    role: "EMPLOYEE",
    services: ["5"],
  },
  {
    name: "Ana Pereira",
    email: "ana.pereira@example.com",
    phone: {
      ddd: 41,
      phone: 966666666,
    },
    department: "Limpeza",
    companyId: "123",
    active: true,
    role: "SUPERVISOR",
    services: ["1", "6"],
  },
  {
    name: "Pedro Costa",
    email: "pedro.costa@example.com",
    phone: {
      ddd: 51,
      phone: 955555555,
    },
    department: "Manutenção",
    companyId: "123",
    active: true,
    role: "EMPLOYEE",
    services: ["2", "3", "4"],
  }
];

export const employeesData: IEmployee[] = [
  {
    id: "1",
    name: "João Silva",
    email: "joao.silva@example.com",
    phone: 5521999999999,
    company_id: "123",
    role: "Manager",
    created_at: "2023-12-01T10:00:00Z",
    active: true,
    services: [
      {
        id: "1",
        name: "Limpeza Residencial",
        price: 120.0,
        averageExecutionTime: 90,
        company_id: "123",
        category_id: "1",
        category_name: "Limpeza",
        created_at: "2023-12-01T10:00:00Z",
      },
    ],
  },
  {
    id: "2",
    name: "Maria Oliveira",
    email: "maria.oliveira@example.com",
    phone: 5521988888888,
    company_id: "123",
    role: "Employee",
    created_at: "2023-12-02T11:00:00Z",
    active: true,
    services: [
      {
        id: "2",
        name: "Manutenção Elétrica",
        price: 200.0,
        averageExecutionTime: 120,
        company_id: "123",
        category_id: "2",
        category_name: "Manutenção",
        created_at: "2023-12-02T11:00:00Z",
      },
    ],
  },
  {
    id: "3",
    name: "Carlos Mendes",
    email: "carlos.mendes@example.com",
    phone: 5521977777777,
    company_id: "124",
    role: "Supervisor",
    created_at: "2023-12-03T12:00:00Z",
    active: false,
    services: [],
  },
];
