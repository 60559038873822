import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import toast from "react-hot-toast";
import { ICategoryForm } from "../../interfaces/category.interface";

export const CATEGORY_QUERY_KEY = "categories";
const base_url = "/smartagenda/Company"


export function useCategory() {
  const queryClient = useQueryClient();

  const addCategory = useMutation({
    mutationFn: (data: ICategoryForm) => api.post(`${base_url}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CATEGORY_QUERY_KEY] });
      toast.success("Sucesso ao criar categoria!");
    },
    onError: () => {
      toast.error("Erro ao criar categoria!");
    },
  });

  return {
    addCategory
  };
}
