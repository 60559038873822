import React from 'react';
import { Link } from 'react-router-dom';
import { IFlowWhatsApp } from '../../interfaces/flowWhatsApp.interface';
import { FormHeader } from '../../components/Form/FormHeader';
import { FlowCard } from './components/FlowCard';
import { Container } from '../../components/Container';
import { ListHeader } from '../../components/ListHeader';

export const ListFlowWhatsApp: React.FC = () => {
  // Exemplo de dados - substitua por sua chamada API real
  const [flows, setFlows] = React.useState<IFlowWhatsApp[]>([
    {
      name: "Flow de Agendamento",
      companyId: "1",
      steps: {
        step_1: {
          name: "Saudação",
          description: "Olá, bem-vindo!",
          roleId: "greeting_role"
          
        },
        step_2: {
          name: "Seleção de Serviço",
          description: "Escolha um serviço",
          roleId: "service_role"
        }
      }
    },
        {
      name: "Flow de Agendamento",
      companyId: "1",
      steps: {
        step_1: {
          name: "Saudação",
          description: "Olá, bem-vindo!",
          roleId: "greeting_role"
          
        },
        step_2: {
          name: "Seleção de Serviço",
          description: "Escolha um serviço",
          roleId: "service_role"
        }
      }
    },
    // Adicione mais flows conforme necessário
  ]);

  const handleDeleteFlow = (companyId: string) => {
    // Implementar lógica de deleção
    setFlows(flows.filter(flow => flow.companyId !== companyId));
  };

  return (
    <Container>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ListHeader
          title="Flows WhatsApp"
          subtitle="Gerencie seus fluxos de mensagens"
          buttonLabel="Novo Flow"
          buttonLink="/flow/new"
        />

        <div className="grid gap-6">
        {flows.map((flow) => (
              <FlowCard
                key={flow.companyId}
                flow={flow}
                onDelete={handleDeleteFlow}
              />
            ))}
        </div>

        {flows.length === 0 && (
          <div className="text-center py-16 bg-white shadow-md ring-1 ring-gray-900/5 
                       sm:rounded-xl flex flex-col items-center">
            <p className="text-gray-600 text-lg">
              Nenhum flow cadastrado ainda.
            </p>
            <Link
              to="/flow/new"
              className="text-indigo-600 hover:text-indigo-500 font-semibold mt-4 
                       inline-flex items-center gap-2 hover:gap-3 transition-all duration-200"
            >
              Criar primeiro flow
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </Link>
          </div>
        )}
      </div>
    </Container>
  );
};
