export interface IService {
  id?: number;
  name: string;
  averageExecutionTime: number;
}

export interface IServiceForm {
  id?: string;
  name: string;
  price: number;
  averageExecutionTime: number;
  companyId: string;
  categoryName: string;
}

export const servicesOptionsForm: IServiceForm[] = [
  {
    id: "1",
    name: "Limpeza Residencial",
    price: 120.0,
    averageExecutionTime: 90,
    companyId: "123",
    categoryName: "Limpeza",
  },
  {
    id: "2",
    name: "Limpeza",
    price: 120.0,
    averageExecutionTime: 90,
    companyId: "123",
    categoryName: "Limpeza",
  },
  {
    id: "3",
    name: "Manutenção",
    price: 120.0,
    averageExecutionTime: 90,
    companyId: "123",
    categoryName: "Limpeza",
  },
  {
    id: "4",
    name: "Pintura",
    price: 120.0,
    averageExecutionTime: 90,
    companyId: "123",
    categoryName: "Limpeza",
  }, 
  {
    id: "5",
    name: "Pintura",
    price: 120.0,
    averageExecutionTime: 90,
    companyId: "123",
    categoryName: "Limpeza",
  },  

];

export interface INewService {
  id: string;
  name: string;
  price: number;
  averageExecutionTime: number;
  company_id: string; 
  category_id: string;
  category_name: string;
  created_at: string;
}

