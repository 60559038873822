import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import paperAirplane from "../../../images/paperAirplane.png";
import menSendMessage from "../../../images/menSendMessage.png";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { addCompanyValidation } from "../../../utils/zodValidations/companyValidation";

type RegisterFormValues = z.infer<typeof addCompanyValidation>;

export function Register() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegisterFormValues>({
    resolver: zodResolver(addCompanyValidation),
  });

  const onSubmit = (data: RegisterFormValues) => {
    console.log(data);
  };

  return (
    <div className="min-h-screen flex items-center bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="flex-1 flex flex-col-reverse lg:flex-row items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Left side - Image */}
        <div className="w-full lg:w-1/2 flex justify-center p-8">
          <img
            src={menSendMessage}
            alt="Paper Airplane"
            className="max-w-md w-full object-contain drop-shadow-2xl"
          />
        </div>

        {/* Right side - Form */}
        <div className="w-full lg:w-1/2 space-y-6 p-8">
          <div className="text-center">
            <img
              src={paperAirplane}
              alt="Logo"
              className="h-12 w-auto mx-auto drop-shadow-md"
            />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
              Cadastre sua Empresa
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Preencha os dados para criar sua conta
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Nome
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  {...register("name")}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm transition-all duration-200"
                  placeholder="Digite o nome da Empresa"
                />
                {errors.name && (
                  <p className="mt-2 text-sm text-red-600">{errors.name.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                CNPJ
              </label>
              <div className="mt-1">
                <InputMask
                  mask="99.999.999/9999-99"
                  {...register("cnpj")}
                  onChange={(e) => setValue("cnpj", e.target.value)}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm transition-all duration-200"
                  placeholder="Digite o CNPJ"
                />
                {errors.cnpj && (
                  <p className="mt-2 text-sm text-red-600">{errors.cnpj.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Telefone
              </label>
              <div className="mt-1">
                <InputMask
                  mask="(99) 99999-9999"
                  {...register("phone")}
                  onChange={(e) => setValue("phone", e.target.value)}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm transition-all duration-200"
                  placeholder="Digite o telefone"
                />
                {errors.phone && (
                  <p className="mt-2 text-sm text-red-600">{errors.phone.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  {...register("email")}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm transition-all duration-200"
                  placeholder="Digite seu email"
                />
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Senha
              </label>
              <div className="mt-1">
                <input
                  type="password"
                  {...register("password")}
                  className="block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm transition-all duration-200"
                  placeholder="Digite sua senha"
                />
                {errors.password && (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            <div className="space-y-4 pt-4">
              <button
                type="submit"
                className="flex w-full justify-center rounded-lg bg-indigo-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-200"
              >
                Registrar
              </button>

              <Link to="/">
                <button
                  type="button"
                  className="flex w-full justify-center rounded-lg bg-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 transition-all duration-200"
                >
                  Voltar para Login
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
