import { Link } from "react-router-dom";

interface ListHeaderProps {
    title: string;
    subtitle?: string;
    buttonLabel: string;
    buttonLink: string;
}

export const ListHeader = ({ title, subtitle, buttonLabel, buttonLink }: ListHeaderProps) => {
    return (
        <div className="md:flex md:items-center md:justify-between mb-8">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {title}
                </h2>
                {subtitle && (
                    <p className="mt-1 text-sm leading-5 text-gray-500">
                        {subtitle}
                    </p>
                )}
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0">
                <Link to={buttonLink}>
                    <button
                        type="button"
                        className="group relative inline-flex items-center gap-x-2 rounded-lg bg-indigo-600 px-4 py-2.5 
                                 text-sm font-semibold text-white shadow-md hover:bg-indigo-500 
                                 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                                 focus-visible:outline-indigo-600 transition-all duration-200 ease-in-out
                                 hover:shadow-lg hover:shadow-indigo-500/30 hover:-translate-y-0.5"
                    >
                        <span className="flex items-center gap-x-2">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                strokeWidth={2} 
                                stroke="currentColor" 
                                className="w-5 h-5 transition-transform group-hover:rotate-90 duration-300"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            {buttonLabel}
                        </span>
                    </button>
                </Link>
            </div>
        </div>
    );
};