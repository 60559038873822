import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Container } from "../../components/Container";
import { ICompany } from "../../interfaces/company.interface";
import { InputField } from "../../components/Form/Input/InputField";
import { TimeInput } from "../../components/Form/TimeInput";
import { formatCNPJ } from "../../utils/formatters";
import { useCompany } from "../../services/hooks/useCompany";
import { FormHeader } from "../../components/Form/FormHeader";
import { FormFooter } from "../../components/Form/FormFooter";

const companySchema = z.object({
  name: z.string().min(1, "Nome deve ser preenchido"),
  cnpj: z.string().min(14, "CNPJ deve ter 14 dígitos"),
  startTime: z.string().min(1, "Horário inicial obrigatório"),
  endTime: z.string().min(1, "Horário final deve ser preenchido"),
  email: z.string().email("Email inválido"),
});

type FormData = z.infer<typeof companySchema>;

export const AddCompany = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(companySchema),
  });

  const { addCompany } = useCompany();

  const handleCancel = () => {
    reset();
  };

  const onSubmit = async (data: FormData) => {
    const companyData: ICompany = {
      name: data.name,
      cnpj: formatCNPJ(data.cnpj),
      openingHours: `${data.startTime} às ${data.endTime}`,
      email: data.email,
    };

    await addCompany.mutateAsync(companyData);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <FormHeader
              title="Cadastro de Empresa"
              subtitle="Complete as informações da sua empresa para prosseguir com o cadastro"
            />

            <div className="mt-8 space-y-6 max-w-xl">
              <InputField
                name="name"
                control={control}
                label="Nome Empresa"
                placeholder="Digite o nome da empresa"
                errors={errors}
              />

              <InputField
                name="cnpj"
                control={control}
                label="CNPJ"
                placeholder="XX.XXX.XXX/XXXX-XX"
                errors={errors}
                mask="99.999.999/9999-99"
              />

              <div className="space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Horário de Funcionamento
                </label>
                <div className="flex items-center gap-4">
                  <TimeInput
                    name="startTime"
                    control={control}
                    label="Início"
                    errors={errors}
                  />

                  <TimeInput
                    name="endTime"
                    control={control}
                    label="Fim"
                    errors={errors}
                  />
                </div>
              </div>

              <InputField
                name="email"
                control={control}
                label="Email"
                placeholder="empresa@exemplo.com"
                errors={errors}
              />
            </div>
          </div>

          <FormFooter
            cancelLink="/companies"
            onCancel={handleCancel}
            isValid={isValid}
          />
        </div>
      </form>
    </Container>
  );
};
