import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import toast from "react-hot-toast";
import { IFlowWhatsApp } from "../../interfaces/flowWhatsApp.interface";

export const COMPANY_QUERY_KEY = "FlowWhatsApp";
const base_url = "/smartagenda/FlowWhatsApp"


export function useFlowWhatsApp() {
  const queryClient = useQueryClient();

  const addFlowWhatsApp = useMutation({
    mutationFn: (data: IFlowWhatsApp) => api.post(`${base_url}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMPANY_QUERY_KEY] });
      toast.success("Sucesso ao criar fluxo!");
    },
    onError: () => {
      toast.error("Erro ao criar fluxo!");
    },
  });

  return {
    addFlowWhatsApp
  };
}
