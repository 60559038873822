import React from 'react';
import { useForm } from 'react-hook-form';
import { IFlowWhatsApp, IStep } from '../../interfaces/flowWhatsApp.interface';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormFooter } from '../../components/Form/FormFooter';
import { useFlowWhatsApp } from '../../services/hooks/useFlowWhatsApp';
import { Container } from '../../components/Container';

export const AddFlowWhatsApp: React.FC = () => {
  const { register, handleSubmit, control, reset, formState: { errors } } = useForm<IFlowWhatsApp>({
    defaultValues: {
      name: '',
      steps: {},
      companyId: ''
    }
  });

  const { addFlowWhatsApp } = useFlowWhatsApp();

  const [steps, setSteps] = React.useState<Record<string, IStep>>({});

  const handleAddStep = () => {
    const newStepKey = `step_${Object.keys(steps).length + 1}`;
    setSteps(prev => ({
      ...prev,
      [newStepKey]: {
        name: '',
        description: '',
        roleId: `${newStepKey}_role`
      }
    }));
  };

  const handleRemoveStep = (stepKey: string) => {
    setSteps(prev => {
      const newSteps = { ...prev };
      delete newSteps[stepKey];
      return newSteps;
    });
  };

  const onSubmit = async (data: IFlowWhatsApp) => {
    const formData = {
      ...data,
      steps: steps
    };

    await addFlowWhatsApp.mutateAsync(formData);
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Container>
      <>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Nome do Flow */}
          <div className="space-y-6">
            <div className="border-b border-gray-900/10 pb-6">
              <FormHeader
                title="Flows WhatsApp"
                subtitle="Personalize o fluxo de mensagens"
              />

              <div className="mt-8 space-y-6 max-w-xl">
                <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                  <div className="px-4 py-6 sm:p-8">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8">
                      <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">
                          Nome do Flow
                        </label>
                        <input
                          {...register('name', { required: 'Nome é obrigatório' })}
                          type="text"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 
                               shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                               focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Nome do fluxo de mensagens"
                        />
                        {errors.name && (
                          <span className="text-sm text-red-600">{errors.name.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Botão Adicionar Step */}
                <button
                  type="button"
                  onClick={handleAddStep}
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white 
                       shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                       focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full"
                >
                  <div className="flex items-center justify-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                    </svg>
                    Adicionar Novo Passo
                  </div>
                </button>

                {/* Steps Dinâmicos */}
                {Object.entries(steps).map(([key, step]) => (
                  <div key={key} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                    <div className="px-4 py-6 sm:p-8">
                      <div className="flex items-center justify-between mb-4">
                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 
                                   text-sm font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                          Step {key.split('_')[1]}
                        </span>
                        <button
                          type="button"
                          onClick={() => handleRemoveStep(key)}
                          className="text-gray-400 hover:text-red-500 transition-colors"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>

                      <div className="grid grid-cols-1 gap-y-6">
                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Nome do Passo
                          </label>
                          <input
                            type="text"
                            value={step.name}
                            onChange={(e) => setSteps(prev => ({
                              ...prev,
                              [key]: { ...prev[key], name: e.target.value }
                            }))}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 
                                 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Ex: Saudação Inicial"
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium leading-6 text-gray-900">
                            Descrição
                          </label>
                          <textarea
                            value={step.description}
                            onChange={(e) => setSteps(prev => ({
                              ...prev,
                              [key]: { ...prev[key], description: e.target.value }
                            }))}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 
                                 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 
                                 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Digite a mensagem para este passo"
                            rows={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <FormFooter
              cancelLink="/flow"
              onCancel={handleCancel}
              isValid={true}
            />
          </div>
        </form>
      </>
    </Container>

  );
};
