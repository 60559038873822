import { Button } from '../Button';
import { Link } from 'react-router-dom';

interface FormFooterProps {
  cancelLink: string;
  onCancel?: () => void;
  isSubmitting?: boolean;
  isValid?: boolean;
}

export const FormFooter = ({ 
  cancelLink, 
  onCancel, 
  isSubmitting,
  isValid 
}: FormFooterProps) => {
  return (
    <div className="flex items-center justify-end gap-x-4">
      <Link to={cancelLink}>
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
      </Link>

      <Button type="submit" disabled={!isValid}>
        {isSubmitting ? 'Salvando...' : 'Salvar'}
      </Button>
    </div>
  );
};