import { z } from "zod";

export const serviceSchema = z.object({
    name: z.string().min(1, "Nome do serviço deve ser preenchido"),
    price: z.string().min(0, "O preço deve ser preenchido"),
    averageExecutionTime: z
        .string()
        .min(1, "O tempo de execução deve ser no mínimo 1 minuto"),
    categoryName: z.string().nonempty("A categoria do serviço é obrigatória"),
});
