import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  BookmarkIcon,
  BuildingStorefrontIcon,
  ChatBubbleLeftIcon,
  HomeIcon,
  UserGroupIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import paperAirplane from "../../images/paperAirplane.png";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para navegação

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [navigation, setNavigation] = useState([
    {
      name: "Empresas",
      href: "/companies",
      icon: BuildingStorefrontIcon,
      current: false,
      roles: ["adm@gmail.com"],
    },
    {
      name: "Funcionários",
      href: "/employees",
      icon: UserGroupIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
    {
      name: "Serviços",
      href: "/services",
      icon: WrenchIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
    {
      name: "Categorias",
      href: "/categories",
      icon: BookmarkIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
    {
      name: "Flow WhatsApp",
      href: "/flow",
      icon: ChatBubbleLeftIcon,
      current: false,
      roles: ["empresa@gmail.com"],
    },
  ]);

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem("user") || "{}");

    const filteredNavigation = navigation.filter(
      (item) => !item.roles || item.roles.includes(userProfile)
    );

    setNavigation(
      filteredNavigation.map((item) => ({
        ...item,
        current: location.pathname === item.href,
      }))
    );
  }, [location]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 xl:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gradient-to-b from-gray-900 to-gray-800 px-6 backdrop-blur-lg">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      src={paperAirplane}
                      alt="Logo"
                      className="h-8 w-auto filter drop-shadow-lg"
                    />
                    <span className="text-white ml-2 font-bold tracking-wide">SmartAgenda</span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-2">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-800/50 text-white shadow-lg shadow-gray-800/20"
                                    : "text-gray-400 hover:text-white hover:bg-gray-800/30 transition-all duration-200",
                                  "group flex gap-x-3 rounded-xl p-3 text-sm leading-6 font-medium"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    "h-5 w-5 shrink-0 transition-transform group-hover:scale-110",
                                    item.current ? "text-blue-400" : ""
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                  <div className="mt-auto pb-4">
                    <Link to="/">
                      <button className="w-full flex items-center justify-center gap-2 bg-red-500/80 hover:bg-red-600 text-white transition-all duration-200 rounded-xl p-3 text-sm font-medium shadow-lg hover:shadow-red-500/20">
                        <ArrowLeftOnRectangleIcon
                          className="h-5 w-5 transition-transform group-hover:scale-110"
                          aria-hidden="true"
                        />
                        Sair
                      </button>
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Sidebar desktop */}
      <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gradient-to-b from-gray-900 to-gray-800 px-6 backdrop-blur-lg">
          <div className="flex h-16 shrink-0 items-center">
            <img
              src={paperAirplane}
              alt="Logo"
              className="h-8 w-auto filter drop-shadow-lg"
            />
            <span className="text-white ml-2 font-bold tracking-wide">SmartAgenda</span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-2">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-800/50 text-white shadow-lg shadow-gray-800/20"
                            : "text-gray-400 hover:text-white hover:bg-gray-800/30 transition-all duration-200",
                          "group flex gap-x-3 rounded-xl p-3 text-sm leading-6 font-medium"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            "h-5 w-5 shrink-0 transition-transform group-hover:scale-110",
                            item.current ? "text-blue-400" : ""
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          <div className="mt-auto pb-4">
            <Link to="/">
              <button className="w-full flex items-center justify-center gap-2 bg-red-500/80 hover:bg-red-600 text-white transition-all duration-200 rounded-xl p-3 text-sm font-medium shadow-lg hover:shadow-red-500/20">
                <ArrowLeftOnRectangleIcon
                  className="h-5 w-5 transition-transform group-hover:scale-110"
                  aria-hidden="true"
                />
                Sair
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
