import { AxiosError, AxiosResponse } from "axios";
import api from "./api";
import { ICompany } from "../interfaces/company.interface";

const baseURL = "smartagenda/Company";

export const postCompany = async (data: ICompany): Promise<any> => {
  try {
    const response = await api.post(`/smartagenda/Company`, data);
    return response.data;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      console.error('Erro ao criar empresa:', {
        status: error.response?.status,
        message: error.response?.data || error.message,
        data: error.response?.data
      });
      throw error.response?.data || {
        message: 'Erro ao criar empresa',
        error: error.message
      };
    }
    throw error;
  }
};

export const getAllCompanies = (): Promise<ICompany[]> =>
  api
    .get(`${baseURL}`)
    .then((response: AxiosResponse<ICompany[]>) => response.data);

export const getCompanyId = (id: number): Promise<ICompany[]> =>
  api
    .get(`/${baseURL}/${id}`)
    .then((response: AxiosResponse<ICompany[]>) => response.data);

export const putCompany = (data: ICompany) => {
  const response = api.put(`${baseURL}/${data.cnpj}`, data);
  return response;
};
