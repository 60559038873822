import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../api";
import toast from "react-hot-toast";
import { IServiceForm } from "../../interfaces/services.interface";

export const SERVICE_QUERY_KEY = "services";
const base_url = "/smartagenda/Service"


export function useService() {
  const queryClient = useQueryClient();

  const addService = useMutation({
    mutationFn: (data: IServiceForm) => api.post(`${base_url}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SERVICE_QUERY_KEY] });
      toast.success("Sucesso ao criar serviço!");
    },
    onError: () => {
      toast.error("Erro ao criar serviço!");
    },
  });

  return {
    addService
  };
}
