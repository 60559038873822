import { Control, Controller, FieldErrors } from "react-hook-form";

interface TimeInputProps {
  name: string;
  control: Control<any>;
  label: string;
  errors: FieldErrors<any>;
}

export const TimeInput = ({ name, control, label, errors }: TimeInputProps) => {
  return (
    <div className="flex flex-col gap-1">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            {...field}
            type="time"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        )}
      />
      {errors[name] && (
        <span className="text-sm text-red-600">{errors[name]?.message as string}</span>
      )}
    </div>
  );
}; 